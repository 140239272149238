import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

// Dev Icons
// import { BsArrowRight } from "@react-icons/all-files/di";

const stacks = [
  {
    name: 'Langages & Frameworks',
    techs : [
      {
        label: 'Symfony',
        icon: 'devicon-symfony-plain',
        link: "https://symfony.com"
      },
      {
        label: 'Python',
        icon: 'colored devicon-python-plain',
        link: "https://www.python.org/"
      },
      {
        label: 'Codeigniter',
        icon: 'colored devicon-codeigniter-plain'
      },
      {
        label: 'MySQL',
        icon: 'colored devicon-mysql-plain'
      },
      {
        label: 'React JS',
        icon: 'colored devicon-react-original'
      },
      {
        label: 'jQuery',
        icon: 'colored devicon-jquery-plain'
      },
      {
        label: 'Bootstrap',
        icon: 'colored devicon-bootstrap-plain'
      },
      {
        label: 'GatsbyJS',
        icon: 'colored devicon-gatsby-plain'
      },
    ],
  },
  {
    name: 'Outils',
    techs : [ 
      {
        label: 'VS Code',
        icon: 'colored devicon-vscode-plain'
      },
      {
        label: 'Atom',
        icon: 'colored devicon-atom-plain'
      },
      {
        label: 'Git',
        icon: 'colored devicon-git-plain'
      },
      {
        label: 'Github',
        icon: 'devicon-github-original'
      },
      {
        label: 'Jupyter',
        icon: 'colored devicon-jupyter-plain'
      },
    ],
  },
  {
    name: 'Devops',
    techs : [ 
      {
        label: 'Docker',
        icon: 'colored devicon-docker-plain'
      },
      {
        label: 'CircleCI',
        icon: 'devicon-circleci-plain'
      },
    ],
  },
]

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const IndexPage = () => (
  <Layout>
    <Seo title="Accueil" />
    <div className={styles.textLeft}>
      <div style={{
        display: 'flow-root',
        }}>
        <div style={{
          maxWidth: '23rem',
          margin: '0 2rem 0 0',
          float: 'left',
        }}>
          <h1 className={styles.textTitle} style={{
              justifyContent: `flex-start`,
            }}>
            Guillaume Dominici
          </h1>
          <p className={styles.subtextTitle}>
            Ingénieur logiciel et Architecte Data 
          </p>
          <p className={styles.description}>
            24 ans.
            <br></br>
            Pas de café, l'eau c'est la vie. 
          </p>
        </div>
        <div id="profilImage" style={{
          float: 'right',
        }}>
          <StaticImage
            src="../images/avatar.jpg"
            loading="eager"
            width={120}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            style={{
              borderRadius: '50%'
            }}
          />
        </div>
      </div>
      <div>
        <div className={styles.profilePart}>
          {stacks.map((techs, i) => (
            <div className={styles.techPart}>
            <h2>
              {techs.name}
            </h2>
            <div className={styles.listCards}>
            {techs.techs.map((oc, i) => (
              <div className={styles.card} style={{
                // backgroundColor: `rgba(${r()}, ${r()}, ${r()}, 0.7)`,
              }}>
                <a data-toggle="tooltip" title={oc.label} href={oc.link} target="_blank"><i className={oc.icon}></i></a>
              </div>
            ))}
            </div>
          </div>
          ))}
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage

var r = () => Math.random() * 256 >> 0;
