// extracted by mini-css-extract-plugin
export var card = "index-module--card--HVafm";
export var description = "index-module--description--+9863";
export var displayFlex = "index-module--displayFlex--Uu3ry";
export var experiencesPart = "index-module--experiencesPart--OLRbG";
export var formationsPart = "index-module--formationsPart--n8U8L";
export var intro = "index-module--intro--C4dpM";
export var knowMore = "index-module--knowMore--30tsq";
export var list = "index-module--list--+0B75";
export var listCards = "index-module--listCards--t4EBC";
export var listItem = "index-module--listItem--BubbZ";
export var listItemDescription = "index-module--listItemDescription--84dWA";
export var listItemLink = "index-module--listItemLink--Asns5";
export var profilePart = "index-module--profilePart--hBqKP";
export var subtextTitle = "index-module--subtextTitle--+AidF";
export var techPart = "index-module--techPart--EnVv2";
export var textCenter = "index-module--textCenter--Ts42-";
export var textLeft = "index-module--textLeft--fhKWf";
export var textTitle = "index-module--textTitle--jyeqr";